import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
}

class Utils {
  static getParents (element, selector) {
    const elements = []
    const ishaveselector = selector !== undefined

    while ((element = element.parentElement) !== null) {
      if (element.nodeType !== Node.ELEMENT_NODE) {
        continue
      }

      if (!ishaveselector || element.matches(selector)) {
        elements.push(element)
      }
    }

    return elements
  }
  static createHmac(message, key) {
    return Base64.stringify(hmacSHA256(message, key));
  }
}

export {Utils as default}
