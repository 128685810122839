import Utils from './Utils'

class AddToBasket {
  static bootstrap () {
    const addToBasketNodes = document.querySelectorAll('[data-add-to-basket]');
    [].slice.call(addToBasketNodes).forEach((addToBasketNode) => {
      addToBasketNode.addEventListener('click', (event) => {
        const productWrapperNode = Utils.getParents(event.currentTarget, '[data-product]')[0]
        const variationNode = productWrapperNode.querySelector('[data-seasoning].selected')
        const availabilityNode = variationNode.querySelector('.availability')
        const addToBasketNode = event.currentTarget
        const sizeNode = variationNode.querySelector('[data-type="size"]')
        const nameNode = productWrapperNode.querySelector('[data-type="name"]')
        const productName = productWrapperNode.querySelector('[data-product-name]').innerText
        const quantity = variationNode.querySelector('[data-type="amount"]').value
        const tax = document.querySelector('[data-type="tax"]').innerText
        let size
        let price
        if (sizeNode.tagName === 'SELECT') {
          size = sizeNode[sizeNode.selectedIndex].innerText
          price = sizeNode.value
        } else {
          size = sizeNode.innerText
          price = sizeNode.getAttribute('data-value')
        }
        const name = `${productName} - ${nameNode.options[nameNode.selectedIndex].innerText} - ${size}`
        const weight = size.replace('g', '')
        const formattedTax = tax.replace(',', '').replace('.', '')
        const formattedPrice = price.replace(',', '').replace('.', '')
        const message = `${name};${weight};${formattedPrice};${formattedTax};`;
        const hmac = Utils.createHmac(message, 'f6uu5jk5HuiHxoW0QrqUUamwWaoKOAjAxLI6F4s7fDBWGMzpGbZHdfC1z3KPtrbj2Zbf3Yjl69Lgm6QGwrODOJO8BoNOcLSQD3SHYyj0K9mDINLUFNoVWBUx')
        addToBasketNode.setAttribute('data-name', name)
        addToBasketNode.setAttribute('data-price', formattedPrice)
        addToBasketNode.setAttribute('data-tax', formattedTax)
        addToBasketNode.setAttribute('data-quantity', quantity)
        addToBasketNode.setAttribute('data-hmac', hmac)
        if (window.micromerce && window.micromerce.Order) {
          if(availabilityNode && availabilityNode.innerText && availabilityNode.innerText.indexOf('Nicht Lieferbar') > -1) {
            return;
          }
          micromerce.Order.addItem({
              name,
              weight,
              price: formattedPrice,
              tax: formattedTax,
              quantity,
              hmac
          })
        }
      })
    })
  }
}

export {AddToBasket as default}
