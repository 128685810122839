import Utils from './Utils'

function initVariationSelection () {
  const variationSelectors = document.querySelectorAll('[data-variation-selector]');
  [].slice.call(variationSelectors).forEach((variationSelector) => {
    variationSelector.addEventListener('change', (event) => {
      const changedSelector = event.currentTarget
      const productWrapper = Utils.getParents(changedSelector, '[data-product]')[0]
      const nameNode = productWrapper.querySelector('[data-type="name"]')

      let name
      if (nameNode.options) {
        name = nameNode.options[nameNode.selectedIndex].text
      } else {
        name = nameNode.innerText
      }
      const seasonings = productWrapper.querySelectorAll('[data-seasoning]');
      [].slice.call(seasonings).forEach((seasoning) => {
        if (seasoning.getAttribute('data-seasoning').trim() === name.toLocaleLowerCase()) {
          seasoning.classList.add('selected')
          setPrice(seasoning)
        } else {
          seasoning.classList.remove('selected')
          resetSelectBoxes(seasoning)
        }
      })
    })
  })
}

function resetSelectBoxes (wrapper) {
  const selectBoxes = wrapper.querySelectorAll('[data-variation-selector]');
  [].slice.call(selectBoxes).forEach((selectBox) => {
    selectBox.selectedIndex = 0
  })
}

function setPrice (wrapper) {
  const sizeNode = wrapper.querySelector('[data-type="size"]')
  let size
  if (sizeNode.tagName === 'SELECT') {
    size = sizeNode.value.replace(',', '.')
  } else {
    size = sizeNode.getAttribute('data-value').replace(',', '.')
  }
  const amount = parseInt(wrapper.querySelector('[data-type="amount"]').value)
  const price = (amount * size).toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })
  wrapper.querySelector('[data-price]').innerHTML = price
}

class VariationSelection {
  static bootstrap () {
    initVariationSelection()
  }
}

export {VariationSelection as default}
